::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.container {
  margin-top: 50px;
}
.card {
  margin-bottom: 20px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.card:hover {
  transform: scale(1.1);
  color: #fff;
  background-color: #04f06e;
}
.card:active {
  opacity: 0.5;
  transform: scale(1);
}
.card-img-top {
  height: 200px;
  object-fit: cover;
}
.play-button {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: #04f06e;
  cursor: pointer;
}
.home:hover{
  cursor: pointer;
}

.modal-content{
  background-color: #212529;
  color: #fff;
}

.nothing-found{
  color: #fff;
}

.card-container{
  margin-top: 5rem;
}

.foot-app{
  width: 3rem;
  cursor: pointer;
}

.modal-header .close{
  color: #fff
}

.loader-container {
  height: 100vh;
  position: fixed;
  background: #262546;
  z-index: 12;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover, a:focus {
  text-decoration: none;
}

.footer-form {
  background: #212529;
  border-color: #545454;
}

.form-control:focus {
  background: #f3f4f6;
}

.btn-outline-success, button:focus {
  outline: 1px dotted;
}

footer {
  background: #262546;
  border-top: 1px solid #fff;
}